import styled from "styled-components/macro";

export const Header = styled.header.attrs(props => ({
  "aria-hidden": props.skipHeader ? "true" : "false"
}))`
  position: sticky;
  top: 0;
  z-index: 200;
  background-color: ${(props) => props.backgroundColor};
`;

export const HeaderDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  min-height: 52px;
`;

export const ArrowDiv = styled.div`
  width: 40px;
  align-self: center;
`;
export const LogoDiv = styled.div`
  margin: 0 0.75em;
`;

export const LanguageDiv = styled.div`
  width: 50px;
  align-self: center;
`;
export const Button = styled.button`
  margin-left: -10px;
  border: none;
  background: transparent;
  width: 100%;
`;

export const Title = styled.h1`
  margin: 0 0.5rem;
  flex: 1;
  font-size: 16px;
  text-align: ${(props) => props.textAlign} !important;
  color: ${(props) => props.color};
`;

export const Progress = styled.progress`
  display: block;
  margin: 0;
  border-radius: 0;
  width: 100%;
  padding: 0;
  height: 3px;

  &[value]::-webkit-progress-bar {
    background-color: ${(props) => props.theme.lightColor};
    border-radius: 0px;
  }

  &[value]::-webkit-progress-value {
    background-color: ${(props) => props.theme.mainColor};
    border-radius: 0;
  }
`;
