import { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import * as S from "./PageGDPR.styles";
import PageContent from "Components/PageContent";
import { nextPage } from "logic/actions/navigation";
import { useDispatch, useSelector } from "react-redux";
import * as Api from "logic/api";
import { logEvent, step_completed } from "logic/eventLogger";
import { useTranslation } from "react-i18next";
import { userConsentRequest } from "./consentRequest";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import DaonButton from "Components/common/DaonButton";
import DaonCheckbox from "Components/common/DaonCheckbox";
import DaonErrorBox from "Components/common/DaonErrorBox";
import { ReactComponent as ArrowRight } from "../../assets/icons/16-24/ArrowRight.svg";
import { isMobile } from "logic/deviceType";
import { selectIsCompositeFlow } from "logic/reducers/composite";
import { ThemeContext } from "styled-components";
import { cancelConsent } from "logic/api/application";

export default function PageGDPR() {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  let [isDisabled, setIsDisabled] = useState(true);
  const isCompositeFlow = useSelector(selectIsCompositeFlow);
  const modalContentRef = useRef(null); // Ref for the modal content

  useEffect(() => {
    document.title = `${t("PageGDPR.title")} | Onboarding`;
    if (!getTimePassed("gdpr")) startEventTimer("gdpr");
    const paraElement = document.getElementById("page-notification");
    if (paraElement) {
      paraElement.focus();
    }
    setTimeout(() => {
      setIsDisabled(false);
    }, 2000);
  }, [t]);

  useEffect(() => {
    const content = modalContentRef.current;
    if (!content) return;
    let focusTimeout = null;

    function handleScroll() {
      const isAtBottom =
        content.scrollTop + content.clientHeight >= content.scrollHeight;
      
      if (isAtBottom) {
        if (focusTimeout) {
          clearTimeout(focusTimeout);
        }
        focusTimeout = setTimeout(() => {
          const closeButton = document.querySelector('.modal-header .close');
          if (closeButton && document.activeElement !== closeButton) {
            closeButton.focus();
          }
        }, 100);
      } else {
        if (focusTimeout) {
          clearTimeout(focusTimeout);
          focusTimeout = null;
        }
      }
    }

    function handleFocusIn(e) {
      if (content.contains(e.target)) {
        const closeButton = document.querySelector('.modal-header .close');
        if (closeButton && e.target !== closeButton) {
          closeButton.focus();
        }
      }
    }

    content.addEventListener("scroll", handleScroll);
    content.addEventListener("focusin", handleFocusIn);

    return () => {
      if (focusTimeout) clearTimeout(focusTimeout);
      content.removeEventListener("scroll", handleScroll);
      content.removeEventListener("focusin", handleFocusIn);
    };
  }, [showTermsOfUse]);

  useEffect(() => {
    if (!showTermsOfUse) return;
    const modalElement = document.querySelector(".modal");
    if (!modalElement) return;

    function handleGlobalFocusIn(e) {
      if (!modalElement.contains(e.target)) {
        const closeButton = modalElement.querySelector(".modal-header .close");
        if (closeButton) {
          closeButton.focus();
        }
      }
    }

    document.addEventListener("focusin", handleGlobalFocusIn);
    return () => {
      document.removeEventListener("focusin", handleGlobalFocusIn);
    };
  }, [showTermsOfUse]);

  // New useEffect: IntersectionObserver for the sentinel element
  useEffect(() => {
    if (!showTermsOfUse) return;
    const sentinel = document.getElementById("modal-end-sentinel");
    if (!sentinel) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const closeButton = document.querySelector(".modal-header .close");
            if (closeButton) {
              closeButton.focus();
            }
          }
        });
      },
      {
        threshold: 1.0,
        root: modalContentRef.current,
      }
    );

    observer.observe(sentinel);

    return () => {
      observer.disconnect();
    };
  }, [showTermsOfUse]);

  function toggleCheckBox(e) {
    setChecked(e.target.checked);
  }

  function next() {
    if (checked) {
      nextPage()(dispatch);
    }
  }

  function cancelConset() {
    cancelConsent()
      .then((response) => {
        location.replace(response.data.redirectUrl);
      })
      .catch(() => {
        setHasError(true);
      });
  }

  function acceptGDPR() {
    if (!isInProgress && checked) {
      setIsInProgress(true);
      Api.sendGDPRConsent(userConsentRequest)
        .then(() => {
          const stepName = "gdpr";
          const timePassed = getTimePassed(stepName);
          logEvent(step_completed, {
            stepNameCompleted: stepName,
            timePassed,
          });
          next();
        })
        .catch(() => {
          setHasError(true);
          setIsInProgress(false);
        });
    } else {
      if (!checked) {
        const errorBox = document.getElementById("page-notification");
        setTimeout(() => {
          errorBox?.focus();
        }, 100);
      }
    }
  }

  function toggleTermsOfUse() {
    setShowTermsOfUse(!showTermsOfUse);
  }

  function togglePrivacyPolicy() {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  }

  function navigateToBiometricPolicy() {
    const target = isCompositeFlow ? "_self" : "_blank";
    window.open("https://www.daon.com/biometric-privacy-policy/", target);
  }

  const formatTermsOfUseContent = () => {
    const content = t("PageGDPR.terms_of_use_content");
    return content.replace(
      /clauses/g,
      '<span aria-label="claw-zes">clauses</span>'
    );
  };

  return (
    <PageContent title={t("PageGDPR.title")} instructions={true}>
      <S.Content>
        <div>
          <h2>{t("PageGDPR.title")}</h2>
          {!checked && (
            <S.WarningWrap id="page-notification" tabIndex="-1">
              <DaonErrorBox
                whiteBackground
                message={t("PageGDPR.warning_message")}
              ></DaonErrorBox>
            </S.WarningWrap>
          )}
          <S.CheckWrapper>
            <DaonCheckbox checked={checked} onChange={toggleCheckBox} />
            <p style={{ display: "inline-block", width: !isMobile(navigator.userAgent) ? "80%" : "null" }}>
              {t("PageGDPR.consent_statement")}
            </p>
          </S.CheckWrapper>
          {isMobile(navigator.userAgent) ? (
            <S.CardListItem>
              <S.MobileButton onClick={toggleTermsOfUse} id="terms_of_use_btn">
                <S.MobileLinkText upper data-toggle="modal">
                  {t("PageGDPR.terms_of_use")}
                </S.MobileLinkText>
                <ArrowRight
                  style={{ float: "right", margin: "4px" }}
                  fill={theme.mainColor}
                />
              </S.MobileButton>
              <S.MobileButton onClick={togglePrivacyPolicy} id="privacy_btn">
                <S.MobileLinkText data-toggle="modal">
                  {t("PageGDPR.view_privacy_policy")}
                </S.MobileLinkText>
                <ArrowRight
                  style={{ float: "right", margin: "4px" }}
                  fill={theme.mainColor}
                />
              </S.MobileButton>
              <S.MobileButton onClick={navigateToBiometricPolicy} id="biometric_policy_btn">
                <S.MobileLinkText data-toggle="modal">
                  {t("PageGDPR.view_biometric_policy")}
                </S.MobileLinkText>
                <ArrowRight
                  style={{ float: "right", margin: "4px" }}
                  fill={theme.mainColor}
                />
              </S.MobileButton>
            </S.CardListItem>
          ) : (
            <S.CardListItem>
              <S.TermPolicyButton
                upper
                onClick={toggleTermsOfUse}
                data-toggle="modal"
                id="terms_of_use_link"
              >
                {t("PageGDPR.terms_of_use")}
              </S.TermPolicyButton>
              <S.TermPolicyButton
                onClick={togglePrivacyPolicy}
                data-toggle="modal"
                id="privacy_link"
              >
                {t("PageGDPR.view_privacy_policy")}
              </S.TermPolicyButton>
              <S.TermPolicyButton
                onClick={navigateToBiometricPolicy}
                id="biometric_link"
              >
                {t("PageGDPR.view_biometric_policy")}
              </S.TermPolicyButton>
            </S.CardListItem>
          )}
        </div>
        <S.BoxDiv>
          {hasError && (
            <S.ErrorWrap>
              <DaonErrorBox
                whiteBackground
                message={t("Common.error")}
              ></DaonErrorBox>
            </S.ErrorWrap>
          )}
          <DaonButton
            onClick={acceptGDPR}
            disabled={isInProgress}
            type="button"
            id="agree"
          >
            {t("PageGDPR.i_agree")}
          </DaonButton>
          {!isCompositeFlow && (
            <DaonButton
              onClick={cancelConset}
              disabled={isDisabled}
              type="button"
              id="cancelConcent"
            >
              {t("PageGDPR.cancel_consent")}
            </DaonButton>
          )}
        </S.BoxDiv>
      </S.Content>

      <Modal
        show={showTermsOfUse}
        onHide={toggleTermsOfUse}
        enforceFocus={true}
        autoFocus={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p>
              <strong>{t("PageGDPR.terms_of_use")}</strong>
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body ref={modalContentRef}>
          <div
            dangerouslySetInnerHTML={{
              __html: formatTermsOfUseContent(),
            }}
          ></div>
          {/* Sentinel element to detect the end of content */}
          <span
            id="modal-end-sentinel"
            tabIndex="0"
            style={{ visibility: "hidden" }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showPrivacyPolicy} onHide={togglePrivacyPolicy} scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>
              <h1>{t("PageGDPR.privacy_policy")}</h1>
            </p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            style={{ overflowY: "auto", maxHeight: "50vh" }}
            dangerouslySetInnerHTML={{
              __html: t("PageGDPR.privacy_policy_content"),
            }}
          ></div>
        </Modal.Body>
      </Modal>
    </PageContent>
  );
}
