import * as S from "./ImagePreview.styles";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import enlargeImg from "assets/icons/16-24/Enlarge.svg";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { formatPreviewImage } from "logic/formatsBase64WithPrefix";
import "yet-another-react-lightbox/styles.css";

const ImagePreview = ({ showZoomedImage, previewImage, className, altText }) => {
  const { t } = useTranslation();
  const [fullScreen, setFullScreen] = useState(false);
  const formatedImage = formatPreviewImage(previewImage);
  return (
    <S.DaonWrap className={className}>
      <S.DaonPreviewImage
        className="image-preview"
        onClick={showZoomedImage}
        src={formatedImage}
        alt={altText}
      />
      <S.EnlargeBoxWrapper>
        <S.EnlargeBox onClick={() => setFullScreen(true)} id="enlarge_image">
          <img src={enlargeImg} alt="+" width="16" height="16" />
          <span id="enlargeImg">{t("Common.enlarge")}</span>
        </S.EnlargeBox>
      </S.EnlargeBoxWrapper>
      <Lightbox
        open={fullScreen}
        close={() => setFullScreen(false)}
        plugins={[Fullscreen, Zoom]}
        slides={[{ src: formatedImage }]}
        carousel={{ finite: true }}
      />
    </S.DaonWrap>
  );
};

export default ImagePreview;
